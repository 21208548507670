import React from 'react';
import {isMobile} from "react-device-detect";
import {googleScriptLoadingDelay} from "../../utils/articleUtil";
import '../../css/components/_share-toolbox.scss'

const PROPERTY_ID = process.env.GATSBY_DOMAIN === '.com' ? '644baf0fac242f001bf9c4bf' : '64462521b4717c0019327d90'

class Share extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null
    }
  }
  componentDidMount() {
    this.setState({url: window.location.href});
    setTimeout(() => {
      const headElement = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = `https://platform-api.sharethis.com/js/sharethis.js#property=${PROPERTY_ID}&product=sop`;
      headElement.append(script);
      try {
        //window.addthis.layers.refresh();
        window.__sharethis__.initialize();
      } catch (e) {
        console.log(e);
      }
    }, googleScriptLoadingDelay() + (isMobile ? 8000 : 5000));
  }

  render() {
    return <div className="sharethis-inline-share-buttons" data-url={this.state.url}></div>;
  }
}

export default Share;
